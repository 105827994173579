import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Sitemark from './SitemarkIcon';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: '8px 12px',
}));

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleSignUpClick = () => {
    if (isDesktop) {
      window.location.href = 'https://professional.sereneminds.life/';
    } else {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <AppBar
      position="fixed"
      enableColorOnDark
      sx={{
        boxShadow: 0,
        bgcolor: 'transparent',
        backgroundImage: 'none',
        mt: 'calc(var(--template-frame-height, 0px) + 28px)',
      }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
            <Sitemark />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Button variant="text" color="info" size="small" href="#highlights">
              Highlights
            </Button>
            <Button variant="text" color="info" size="small" href="#features">
              Features
            </Button>
            <Button variant="text" color="info" size="small" href="#pricing">
              Pricing
            </Button>
            <Button variant="text" color="info" size="small" href="#faq">
              FAQ
            </Button>
            <Button variant="text" color="info" size="small" href="/blog">
              Blog
            </Button>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 1, alignItems: 'center' }}>
            <Button color="secondary" variant="contained" size="small" onClick={handleSignUpClick}>
              Sign up
            </Button>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, gap: 1 }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <MenuItem component="a" href="#highlights">Highlights</MenuItem>
                <MenuItem component="a" href="#features">Features</MenuItem>
                <MenuItem component="a" href="#pricing">Pricing</MenuItem>
                <MenuItem component="a" href="#faq">FAQ</MenuItem>
                <MenuItem component="a" href="/blog">Blog</MenuItem>
                <MenuItem>
                  <Button color="secondary" variant="outlined" fullWidth onClick={handleSignUpClick}>
                    Sign up
                  </Button>
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>

      <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '400px' },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: 'center',
          }}
        >
          <Typography id="modal-title" variant="h6" sx={{ mb: 2 }}>
            Serene MINDS is now live!
          </Typography>
          <Typography id="modal-description" sx={{ mb: 3 }}>
            For the best experience, please access it on a desktop or laptop. We are actively working to optimize it for mobile devices. Stay tuned for updates!
          </Typography>
          <Button variant="contained" onClick={handleCloseModal}>
            Close
          </Button>
        </Box>
      </Modal>
    </AppBar>
  );
}
