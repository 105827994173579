import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';

const API_BASE_URL = "https://api.sereneminds.life/api";

// Utility function to strip HTML tags
const stripHtmlTags = (html) => {
  // Replace HTML tags with a space to preserve spacing
  const text = html.replace(/<[^>]+>/g, ' ');
  // Trim extra spaces and replace multiple spaces with a single space
  return text.replace(/\s+/g, ' ').trim();
};

// Styled components for blog card
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: theme.shadows[6],
    cursor: 'pointer',
  },
}));

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  flexGrow: 1,
});

const StyledTypography = styled(Typography)({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

// Blog Card Component
function BlogCard({ data, onClick }) {
  return (
    <StyledCard variant="outlined" onClick={onClick}>
      <CardMedia
        component="img"
        alt={data.title}
        image={data.img} // Use the fallback image logic
        sx={{
          aspectRatio: '16 / 9',
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      />
      <StyledCardContent>
        <Typography gutterBottom variant="h6" component="div">
          {data.title}
        </Typography>
        <StyledTypography variant="body2" color="text.secondary" gutterBottom>
          {data.description}
        </StyledTypography>
        <Button
          variant="text"
          color="primary"
          sx={{ alignSelf: 'flex-start', textTransform: 'none' }}
          onClick={onClick}
        >
          Read more
        </Button>
      </StyledCardContent>
    </StyledCard>
  );
}

// MainContent Component (Handles both Blog List and Individual Blog View)
export default function MainContent() {
  const [blogs, setBlogs] = useState([]);
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchBlog(id); // Fetch specific blog when an ID is present in the URL
    } else {
      fetchAllBlogs(); // Otherwise, fetch all blogs
    }
  }, [id]);

  const fetchAllBlogs = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/blog/all`);
      setBlogs(response.data.data);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  const fetchBlog = async (blogId) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/blog/${blogId}`);
      setBlog(response.data.data);
    } catch (error) {
      console.error("Error fetching blog post:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = (blog) => {
    navigate(`/blog/${blog.slug}`);
  };

  const handleBack = () => {
    setBlog(null);
    navigate('/');
  };

  // If viewing a single blog post
  if (id && blog) {
    // Fallback image logic
    const imageUrl = blog.blog_photo || `https://picsum.photos/800/450?random`;

    return (
      <Box sx={{ maxWidth: 800, mx: 'auto', p: 4 }}>
        <Button variant="outlined" onClick={handleBack} sx={{ mb: 2 }}>
          ← Back
        </Button>
        <Typography variant="h3" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
          {blog.title}
        </Typography>
        <Box
          component="img"
          src={imageUrl} // Use the fallback image logic
          alt={blog.title}
          sx={{
            width: '100%',
            maxHeight: 400,
            objectFit: 'cover',
            borderRadius: 2,
            mb: 3,
          }}
        />
        <Typography variant="body1" sx={{ lineHeight: 1.8, textAlign: 'justify' }}>
          {stripHtmlTags(blog.content)}
        </Typography>
      </Box>
    );
  }

  // Show loading while fetching single blog
  if (id && loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  // If viewing all blogs
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, p: 4 }}>
      <Typography variant="h3" gutterBottom>
        Blog
      </Typography>
      <Typography variant="subtitle1" color="text.secondary">
        Stay updated with expert insights and trends - discover our latest blogs.
      </Typography>
      <Grid container spacing={4}>
        {blogs.map((blog, index) => {
          // Fallback image logic for each blog card
          const imageUrl = blog.blog_photo || `https://picsum.photos/800/450?random=${index}`;

          return (
            <Grid item xs={12} md={6} key={blog.id}>
              <BlogCard
                data={{
                  img: imageUrl, // Use the fallback image logic
                  title: blog.title,
                  description: stripHtmlTags(blog.content.substring(0, 100) + '...'),
                }}
                onClick={() => handleCardClick(blog)}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}