import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/system';

const userTestimonials = [
  {
    avatar: 'https://qmdfzzfphkfybewcyhej.supabase.co/storage/v1/object/public/professional_profilepic//Screenshot_20250322-212255.png',
    name: 'Hemanshi Jethani',
    occupation: 'Behavioural Psychologist',
    testimonial:
      "This platform has made managing my online clients so much easier! It schedules appointments, sets calendar reminders, and keeps everything organized. A great tool for seamless client engagement.",
  },
  {
    avatar: 'https://qmdfzzfphkfybewcyhej.supabase.co/storage/v1/object/public/professional_profilepic//1000031106.jpeg',
    name: 'Khushi A.',
    occupation: 'Counseling Psychologist',
    testimonial:
      "It’s been a really simple and easy to use Platform. It’s quite user-friendly. It does not confuse the users or the experts. My Clientele has grown a lot ever since I registered on serene minds.",
  },
  {
    avatar: 'https://qmdfzzfphkfybewcyhej.supabase.co/storage/v1/object/public/professional_profilepic//profile-1742647073834%20(1).jpg',
    name: 'Darshni Gosrani',
    occupation: 'Counseling Psychologist',
    testimonial:
      'Serene Minds has completely transformed how I manage my practice. The automated scheduling and whatsapp reminders have eased my work, and the client management tool helps keeping record of each client.',
  },
  {
    avatar: 'https://qmdfzzfphkfybewcyhej.supabase.co/storage/v1/object/public/professional_profilepic//profile-1742115302995%20(1).jpg',
    name: 'Gunjan Arya',
    occupation: 'Counseling Psychologist',
    testimonial:
      "I appreciate the attention to detail in the design of this product. The small things make a big difference, and it's evident that the creators focused on delivering a premium experience.",
  },
  {
    avatar: 'https://qmdfzzfphkfybewcyhej.supabase.co/storage/v1/object/public/professional_profilepic/1741595968093-1000108592.webp',
    name: 'Sakshi Chauhan',
    occupation: 'Clinical Psychologist',
    testimonial:
      "I’ve used other platforms before like Tealfeed, but Serene Minds truly stands out. The features are well thought out, making it so much easier to manage my clients and appointments.",
  },
  {
    avatar: 'https://qmdfzzfphkfybewcyhej.supabase.co/storage/v1/object/public/professional_profilepic/profile-1742041167674.jpeg',
    name: 'Aditi Bansal',
    occupation: 'Counseling Psychologist',
    testimonial:
      "This platform is well-designed and incredibly efficient. Every feature feels intentional, making my work so much easier. It’s definitely a must-have for psychologists!",
  },
];

export default function Testimonials() {
  const theme = useTheme();

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Testimonials
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          See why psychologists love Serene MINDS. Discover how it simplifies practice management, enhances efficiency, and improves client engagement. Join a community of professionals who value innovation, reliability, and seamless support.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index} sx={{ display: 'flex' }}>
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
              }}
            >
              <CardContent>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ color: 'text.secondary' }}
                >
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <CardHeader
                  avatar={<Avatar alt={testimonial.name} src={testimonial.avatar} />}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
