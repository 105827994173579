import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

const API_BASE_URL = "https://api.sereneminds.life/api";

const createSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

const AdminBlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [blogPhoto, setBlogPhoto] = useState('');
  const editorRef = React.useRef(null);
  const quillInstanceRef = React.useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/blog/all`);
        setBlogs(response.data.data);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };
    fetchBlogs();
  }, []);

  useEffect(() => {
    if (editorRef.current && !quillInstanceRef.current) {
      quillInstanceRef.current = new Quill(editorRef.current, {
        theme: 'snow',
        placeholder: 'Write your blog here...',
        modules: { toolbar: true },
      });

      quillInstanceRef.current.on('text-change', () => {
        setContent(quillInstanceRef.current.root.innerHTML.trim());
      });
    }

    if (selectedBlog && quillInstanceRef.current) {
      quillInstanceRef.current.root.innerHTML = selectedBlog.content.trim();
    }
  }, [selectedBlog]);

  const handleSelectBlog = (blog) => {
    setSelectedBlog(blog);
    setTitle(blog.title);
    setContent(blog.content);
    setBlogPhoto(blog.blog_photo || '');
  };

  const cleanContent = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  const handleSave = async () => {
    if (!title || !content) {
      alert('Please fill in both title and content.');
      return;
    }
  
    const slug = createSlug(title);
  
    try {
      if (selectedBlog) {
        await axios.put(`${API_BASE_URL}/blog/${selectedBlog.id}`, {
          title,
          content, // Save the raw HTML content
          blog_photo: blogPhoto,
          slug,
        });
        const updatedBlogs = blogs.map((blog) =>
          blog.id === selectedBlog.id
            ? { ...blog, title, content, blog_photo: blogPhoto, slug }
            : blog
        );
        setBlogs(updatedBlogs);
        alert('Blog updated successfully!');
      } else {
        const response = await axios.post(`${API_BASE_URL}/blog/create`, {
          title,
          content, // Save the raw HTML content
          blog_photo: blogPhoto,
          slug,
        });
        setBlogs([...blogs, response.data.data]);
        setSelectedBlog(response.data.data);
        alert('Blog created successfully!');
      }
    } catch (error) {
      console.error('Error saving blog:', error);
      alert('Error saving blog!');
    }
  };

  const handleDelete = async (blogId) => {
    if (window.confirm('Are you sure you want to delete this blog?')) {
      try {
        await axios.delete(`${API_BASE_URL}/blog/${blogId}`);
        setBlogs(blogs.filter((blog) => blog.id !== blogId));
        if (selectedBlog && selectedBlog.id === blogId) {
          setSelectedBlog(null);
          setTitle('');
          setContent('');
          setBlogPhoto('');
        }
        alert('Blog deleted successfully!');
      } catch (error) {
        console.error('Error deleting blog:', error);
        alert('Error deleting blog!');
      }
    }
  };

  const handleNewBlog = () => {
    setSelectedBlog(null);
    setTitle('');
    setContent('');
    setBlogPhoto('');
    if (quillInstanceRef.current) {
      quillInstanceRef.current.root.innerHTML = '';
    }
  };

  return (
    <Box sx={{ p: 2, pt: 5, maxWidth: 500, mx: 'auto', textAlign: 'center' }}>
      <Typography variant="h4" fontWeight="normal" gutterBottom>
        Admin Blog Management
      </Typography>

      {/* Blog List */}
      <Box sx={{ bgcolor: '#f9f9f9', borderRadius: 2, p: 2, mb: 2 }}>
        <List sx={{ maxHeight: 200, overflowY: 'auto' }}>
          {blogs.map((blog) => (
            <ListItem
              key={blog.id}
              onClick={() => handleSelectBlog(blog)}
              sx={{
                borderRadius: 2,
                bgcolor: selectedBlog?.id === blog.id ? '#eceaff' : '#fff',
                boxShadow: 1,
                mb: 2,
                cursor: 'pointer',
                '&:hover': { bgcolor: '#f0f0f0' },
              }}
            >
              <ListItemText primary={blog.title} />
              <IconButton
                color="error"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(blog.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 1, borderRadius: 2 }}
          onClick={handleNewBlog}
        >
          New Blog
        </Button>
      </Box>

      {/* Blog Editor */}
      <Box sx={{ bgcolor: '#fff', borderRadius: 2, p: 2, boxShadow: 2 }}>
        <TextField
          fullWidth
          label="Title"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ mb: 2, borderRadius: 2 }}
        />
        <TextField
          fullWidth
          label="Blog Photo URL"
          variant="outlined"
          value={blogPhoto}
          onChange={(e) => setBlogPhoto(e.target.value)}
          sx={{ mb: 2, borderRadius: 2 }}
        />
        <Box
          ref={editorRef}
          sx={{
            border: '1px solid #ccc',
            borderRadius: 2,
            minHeight: 250,
            p: 2,
            boxShadow: 1,
            bgcolor: '#fafafa',
          }}
        />
        <Button
          onClick={handleSave}
          variant="contained"
          color="success"
          fullWidth
          sx={{ mt: 2, borderRadius: 2 }}
        >
          Save Blog
        </Button>
      </Box>
    </Box>
  );
};

export default AdminBlogList;