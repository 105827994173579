import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'quill/dist/quill.snow.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { format } from 'date-fns';

const API_BASE_URL = "https://api.sereneminds.life/api";

const BlogDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/blog/slug/${slug}`);
        setBlog(response.data.data);
        setTitle(response.data.data.title);
        setContent(response.data.data.content);
      } catch (error) {
        console.error('Error fetching blog:', error);
      }
    };
    fetchBlog();
  }, [slug]);

  if (!blog) return <Typography>Loading...</Typography>;

  // Fallback image logic
  const imageUrl = blog.blog_photo || `https://picsum.photos/800/450?random`;

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <ToastContainer />
      <Button
        onClick={() => navigate('/blog')}
        color="primary"
        sx={{ mb: 2 }}
      >
        ← Back to Blogs
      </Button>
      <Box sx={{ px: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
          {blog.title}
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 2 }}>
          <Avatar alt={blog.author} sx={{ width: 35, height: 35 }} src="https://lh3.googleusercontent.com/-gkRPxVsRGbc/AAAAAAAAAAI/AAAAAAAAAAA/ALKGfkltJJKCaUlj_6Uw5pG-CONLa2gkrw/s128-c/photo.jpg" />
          <Typography variant="subtitle1" color="text.secondary">
            Serene MINDS <br /> {format(new Date(blog.created_at), 'MMMM dd, yyyy')}
          </Typography>
        </Stack>
        <Box
          component="img"
          src={imageUrl} // Use the fallback image logic
          alt={blog.title}
          sx={{ width: '100%', height: "100%", objectFit: 'cover', borderRadius: 2, mb: 3 }}
        />
        <Box
          sx={{
            '& p': { marginBottom: 2 }, // Add spacing between paragraphs
          }}
          dangerouslySetInnerHTML={{ __html: blog.content }}
        />
      </Box>
    </Container>
  );
};

export default BlogDetail;
